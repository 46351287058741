
import { Subscription, firstValueFrom } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DownloadedManualErrorCode, DownloadedManualFileType, DownloadedManualStatus, MediaViewModel } from 'root/mibp-openapi-gen/models';
import { MediaApiController } from 'root/mibp-openapi-gen/controllers';
import { ApiService, GlobalConfigService, LogService, MibpHttpApi, MibpLogger, ToastService } from 'root/services';
import { DownloadedManualsService } from 'root/services/downloaded-manuals/downloaded-manuals.service';
import { ActiveMibpToast } from 'root/services/toast-service/toast.service.interface';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { MySandvikFeatures } from 'root/services/permission';
@Component({
  selector: 'mibp-manual-download-button',
  templateUrl: './manual-download-button.component.html',
  styleUrl: './manual-download-button.component.scss',

})
export class ManualDownloadButtonComponent implements OnInit, OnDestroy {

  protected downloadFileTypes = DownloadedManualFileType;
  protected downloadedManualStatus = DownloadedManualStatus;
  private log: MibpLogger;
  private activeToast?: ActiveMibpToast;
  private activeToastActionSub?: Subscription;
  private activeToastCloseSub?: Subscription;
  private mediaDownloadEventsSub?: Subscription;
  protected canDownloadManuals = false;

  @Input() equipmentId: number;
  @Input() downloadErrorCode: DownloadedManualErrorCode;
  @Input() downloadStatus: DownloadedManualStatus;
  @Input() downloadedManualId?: number;
  @Input() fileSize?: number;
  @Input() fileType?: DownloadedManualFileType;
  @Input() mediaId: number;
  @Input() mediaName?: string;
  @Input() shelf?: string ;
  @Input() shelfId: number;
  @Input() userDownloadedManualId?: number;
  @Input() displayBlock = true;

  constructor(
    private signalRApi: ApiService,
    private session: MibpSessionService,
    private mediaApiController: MediaApiController,
    private downloadedManualsService: DownloadedManualsService,
    protected globalConfigService: GlobalConfigService,
    private mibpHttp: MibpHttpApi,
    logService: LogService,
    private toastService: ToastService) {
    this.log = logService.withPrefix('mibp-manual-download-button');
  }
  ngOnDestroy(): void {
    this.mediaDownloadEventsSub?.unsubscribe();

    if (this.activeToast) {
      this.toastService.removeToast(this.activeToast.toastId);
      this.activeToastActionSub?.unsubscribe();
      this.activeToastCloseSub?.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.canDownloadManuals = this.session.hasFeature(MySandvikFeatures.MyfleetDownloadManuals);
    
    this.mediaDownloadEventsSub = this.signalRApi.EventHub.ManualDownloadStatusUpdate.subscribe(event => {
      if (event) {
        if (this.mediaId ==  event.mediaId) {
          this.downloadStatus = (<any>event.status as DownloadedManualStatus);
          this.fileSize = event.pdfFileSize;
          this.downloadErrorCode = <any>event.downloadErrorCode;
          this.fileType = <any>event.fileType;
        }
      }
    });
  }



  async onDownloadManualClick(): Promise<void> {

    if (!this.fileSize) {
      const previousStatus = this.downloadStatus;
      try {
        this.downloadStatus = DownloadedManualStatus.Queued;
        this.userDownloadedManualId = -1;
        const result = await firstValueFrom(this.mediaApiController.requestManualDownload({
          mediaId: this.mediaId,
          equipmentId: this.equipmentId
        }));

        this.downloadStatus = result.userDownloadedManuals[0].downloadedManualStatus;
        this.fileSize = result.userDownloadedManuals[0].fileSize;
        this.userDownloadedManualId = result.userDownloadedManuals[0].userDownloadedManualId;

        if (this.activeToast) {
          this.activeToastActionSub?.unsubscribe();
          this.activeToastActionSub?.unsubscribe();
          this.toastService.removeToast(this.activeToast.toastId);
        }

        if (this.downloadStatus == DownloadedManualStatus.Downloaded) {
          this.downloadedManualsService.showDownloadReadyToast(this.mediaName);
        } else {
          this.downloadedManualsService.showExportStartedToast(this.mediaName);
        }

      } catch (e) {
        this.userDownloadedManualId = null;
        this.downloadStatus = previousStatus;
        this.downloadedManualsService.showErrorStartingExport();
        this.log.error('Error starting export of media', e);
      }

    } else {
      window.location.href = this.mibpHttp.resolveUriWithJwtToken(`media/${this.mediaId}/${this.equipmentId}/download`);

      // When a user downloads a manual that is already available in My Sandvik, but user has not requested the manual

      if (!this.userDownloadedManualId) {
        this.downloadedManualsService.showManualAddedToDownloadsToast(this.mediaName);
        this.userDownloadedManualId = -1; // We will not know the id, but it should exist...
      }
    }
  }

}
